<template>
  <div class="pa-4">
    <p class="font-weight-bold text-center body-2 text-uppercase">{{title}}</p>
    <Pie
      :chart-options="chartOptions"
      :chart-data="dataChart"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>
<script>
import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
export default {
  name: "PieChartDashboard",
  components: { Pie },
  props: {
    title: {type: String},
    chartId: { type: String, default: "pie-chart" },
    datasetIdKey: { type: String, default: "label" },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 300 },
    cssClasses: { default: "", type: String },
    styles: { type: Object, default: () => {} },
    plugins: { type: Array, default: () => [] },
    dataChart: { type: Object, default: () => {} },
  },
  data() {
    return { chartOptions: { responsive: true, maintainAspectRatio: false } };
  },
};
</script>
